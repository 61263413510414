/*
Import custom made Rubik google font and set width and height to full screen for full application.
https://fonts.google.com/ allows you to create a custom font with preset weight and size,
and then import the font into your project via api.
*/
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Rubik", sans-serif;
  scroll-behavior: smooth;
  position: absolute;

}